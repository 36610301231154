const cookieOptions = {
    path: "/",
    domain: ".miracl.at",
    sameSite: "None",
    secure: true,
};

function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

function toCookieString(name, value, options) {
    const params = [[name, value]];

    if (options.path) {
        params.push(["path", options.path]);
    }

    if (options.expires) {
        const expires = typeof options.expires === "string" ? options.expires : options.expires.toUTCString();
        params.push(["expires", expires]);
    }

    if (options.domain) {
        params.push(["domain", options.domain]);
    }

    if (options.sameSite) {
        params.push(["SameSite", options.sameSite]);
    }

    if (options.secure) {
        params.push(["Secure"]);
    }

    return params.map((pair) => pair.join("=")).join(";");
}

function setCookie(name, value, expires) {
    const cookie = toCookieString(name, value, { ...cookieOptions, expires });
    document.cookie = cookie;
}

function addYears(date, years) {
    date.setFullYear(date.getFullYear() + years);
    return date;
}

function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
}

function getCookieParameter(name) {
    return getUrlParameter(name) ?? getCookie(name);
}

// https://stackoverflow.com/a/901144
function getUrlParameter(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function handleParameter(name, expires) {
    var value = getCookieParameter(name);
    if (value) {
        setCookie(name, value, expires);
    }
}

const generateId = () => ~~(Math.random() * 1000000000);

const oneYearFromNow = addYears(new Date(), 1);
const oneDayFromNow = addDays(new Date(), 1);

function storeUserId() {
    const cookieName = "user_id";
    const currentValue = getCookie(cookieName);
    if (currentValue) return currentValue;

    const cookieValue = generateId(); // Random user number
    setCookie(cookieName, cookieValue, oneYearFromNow);
    return cookieValue;
}

function storeSessionId() {
    const cookieName = "sessionId";
    const currentValue = getCookie(cookieName);
    if (currentValue) return currentValue;

    const sessionId = generateId();
    setCookie(cookieName, sessionId, oneYearFromNow);
    return sessionId;
}

// ad click IDs, parsed from URL
handleParameter("fbclid", oneYearFromNow);
handleParameter("gclid", oneYearFromNow);

// UTM parameters, parsed from URL
handleParameter("utm_campaign", oneDayFromNow);
handleParameter("utm_content", oneDayFromNow);
handleParameter("utm_medium", oneDayFromNow);
handleParameter("utm_source", oneDayFromNow);
handleParameter("utm_term", oneDayFromNow);

const userId = storeUserId();
storeSessionId();

/**
 * As per discussion available at:
 * https://github.com/gatsbyjs/gatsby/issues/18866
 * this listener will prevent the ChunkLoadError by
 * reloading the page if it is visited whe the app
 * is being deployed
 */
let nextRoute = ``;

let didSetUserId = false;

exports.onPreRouteUpdate = ({ location }) => {
    nextRoute = location.pathname;

    if (window.gtag && !didSetUserId) {
        window.gtag("set", { user_id: userId });
        didSetUserId = true;
    }
    if (window.Sentry) {
        window.Sentry.setUser({ id: userId });
    }
};

window.addEventListener("unhandledrejection", (event) => {
    if (/loading chunk \d* failed./i.test(event.reason)) {
        if (nextRoute) {
            window.location.pathname = nextRoute;
        }
    }
});

// prefetch caused a massive spike of requests to page-data/faq/ during Google crawl
exports.disableCorePrefetching = () => true;
